import { useEffect, useState } from "react";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "services/firestore";
import { formatDate, addDays } from "utils/date";
import { Post } from "types/common";

export const usePost = () => {
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    const getPost = async () => {
      const date = new Date();
      const today = formatDate(new Date());
      const nextDay = formatDate(addDays(date, 1));

      const ref = collection(db, "posts");
      const q = query(
        ref,
        where("dateString", ">=", today),
        where("dateString", "<", nextDay),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      const temp = [] as any[];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      setPost(temp[0]);
    };
    getPost();
  }, []);

  return { post };
};
