import { Box, BoxProps, Button, Text } from "@mantine/core";
import { formatPrice } from "utils/misc";

type BannerAdProps = {
  address?: string;
  backgroundImage?: string;
  buttonCopy?: string;
  caption?: string;
  foregroundImage?: string;
  logo?: string;
  price?: string | number;
  url?: string;
} & BoxProps;

export const BannerAd = ({
  address = "",
  backgroundImage,
  buttonCopy = "Click me",
  caption,
  foregroundImage,
  logo,
  price = "",
  url,
  ...props
}: BannerAdProps) => {
  return (
    <Box
      sx={{
        background: backgroundImage ? `url(${backgroundImage})` : "#d9d9d9",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        padding: "16px 28px",
        position: "relative",
        width: "100%",
      }}
      {...props}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "black",
          opacity: 0.7,
        }}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "grid",
          gridGap: "20px",
          gridTemplateColumns: "128px 1fr",
          marginBottom: "16px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: {
              color: "white",
              display: "inline-flex",
              fontSize: "12px",
              fontWeight: 600,
              margin: "0",
              marginBottom: "5px",
              wordBreak: "break-word",
            },
            img: {
              height: "auto",
              maxWidth: "100px",
              objectFit: "contain",
              width: "100%",
            },
          }}
        >
          <p>{caption}</p>
          <img src={logo} alt="logo" />
        </Box>

        <Box
          sx={{
            aspectRatio: "3 / 2",
            backgroundColor: "#afafaf",
            border: "1px solid white",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
            img: { aspectRatio: "3 / 2", objectFit: "cover", width: "100%" },
          }}
        >
          {foregroundImage ? (
            <img src={foregroundImage} alt="foreground" />
          ) : null}
          <Box
            py={4}
            px={4}
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              borderTop: "1px solid white",
              bottom: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              left: "0",
              position: "absolute",
              width: "100%",
              p: {
                color: "#E4E4E7",
                flex: 1,
                fontSize: "8px",
                margin: "0",
                padding: "5px",
                wordBreak: "break-word",
              },
            }}
          >
            <Text
              align="center"
              color="#E4E4E7"
              size={10}
              mb={4}
              sx={{ lineHeight: "1" }}
            >
              {formatPrice(+price)}
            </Text>
            <Text
              align="center"
              color="#E4E4E7"
              size={10}
              sx={{ lineHeight: "1" }}
            >
              {address}
            </Text>
          </Box>
        </Box>
      </Box>

      <Button
        component="a"
        href={url}
        target={"_blank"}
        rel="noreferrer noopener"
        sx={(theme) => ({
          backgroundColor: "#F97316",
          borderRadius: "6px",
          color: "white",
          display: "inline",
          fontSize: "12px",
          fontWeight: 500,
          margin: "0 auto",
          padding: "4px 16px",
          wordBreak: "break-word",
          "&:focus,&:hover": {
            cursor: "pointer",
            outline: "none",
            background: theme.colors.poppy[0],
            color: "white",
          },
        })}
      >
        {buttonCopy}
      </Button>
    </Box>
  );
};

export default BannerAd;
