import { format, parse, add } from "date-fns";

const DEFAULT_FORMAT = "yyyy-MM-dd";

export const formatDate = (date?: Date | null, dateFormat = DEFAULT_FORMAT) => {
  if (!date) return "";
  return format(date, dateFormat);
};

export const parseDate = (date?: string, dateFormat = DEFAULT_FORMAT) => {
  if (!date) return new Date();
  return parse(date, dateFormat, new Date());
};

export const parseAndFormatDate = (
  date?: string,
  dateFormat = DEFAULT_FORMAT
) => {
  if (!date) return "";
  const parsedDate = parseDate(date);
  return formatDate(parsedDate, dateFormat);
};

export const addDays = (date: Date, days: number) => {
  return add(date, { days });
};
