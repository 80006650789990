import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colors: {
    black: ["#222222"],
    emerald: ["#164141", "#B6C9C3", "#082525"],
    blush: ["#E2AFBC"],
    poppy: ["#F89558"],
    cream: ["#FFEED3"],
    white: ["#FFFFFF"],
  },
  fontFamily: "Montserrat, sans-serif",
  headings: {
    fontWeight: 800,
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    Title: {
      styles: (theme) => ({
        root: {
          color: theme.colors.emerald[0],
        },
      }),
    },
    Button: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.emerald[0],
          transition: "all 0.25s ease-in-out",
          ":disabled": {
            backgroundColor: theme.colors.emerald[1],
            color: theme.colors.cream[0],
          },
          ":hover:enabled": {
            backgroundColor: theme.colors.emerald[0],
          },
        },
      }),
    },
  },
};
