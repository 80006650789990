import * as React from "react";
import {
  Button,
  Center,
  Flex,
  Stack,
  Text,
  Image,
  Box,
  Space,
  Container,
  Loader,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import Logo from "../images/housle_logo.png";
import Info from "../images/info.png";
import ChevronLeft from "../images/chevron-left.png";
import { HowToPlay } from "../components/HowToPlayModal";
import { AttemptDrawer } from "../components/AttemptDrawer";
import { ZoomComponent } from "../components/Zoom";
import { useMessages } from "../hooks/useMessages";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useNavigate } from "react-router";
import AdsComponent from "components/AdsComponent";

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
//@ts-ignore
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { cacheHintImages, extractNumberFromString } from "../utils/misc";
import { useGame } from "hooks/useGame";
import { logEndGame, logGuesstClick, logStartGame } from "services/analytics";

const Game: React.FC = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [currentClueNum, setCurrentClueNumber] = useState(0);
  const [price, setPrice] = useState<string>("");
  const [attempts, setAttempts] = useState<number[]>([]);
  const [guessMessages, setGuessMessages] = useState<string[]>([]);

  const [guessSubmitted, setGuessSubmitted] = useState(false);
  const [showAttempts, setShowAttempts] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [zoom, setZoom] = useState(false);

  const { post } = useGame();
  const { getTooHighMessage, getTooLowMessage, getLastGuessMessage } =
    useMessages();
  const {
    history,
    completedToday,
    playedToday,
    updateUserHistory,
    setAttemptHistory,
    resetGameForNewDay,
  } = useLocalStorage();
  const navigate = useNavigate();

  const handleEnterKeyPress = React.useCallback(
    (e: any) => {
      e.code === "Enter" && guessSubmitted && handleNextClue();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [guessSubmitted]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [handleEnterKeyPress]);

  useEffect(() => {
    console.log("useEffect");
    if (completedToday) {
      navigate("/results");
    }

    if (!post) return;
    logStartGame({ posting_address: post.address });
    cacheHintImages(post);

    if (playedToday && history.attempts.length > 0) {
      setAttempts(history.attempts);
      setGuessMessages(
        history.attempts.map((att) =>
          att > post.asking_price ? getTooHighMessage() : getTooLowMessage()
        )
      );
    } else {
      setAttempts([]);
      setGuessMessages([]);
      resetGameForNewDay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, completedToday, playedToday, navigate]);

  const toggleZoom = () => setZoom((prev) => !prev);
  const toggleShowAttempts = () => setShowAttempts((prev) => !prev);

  const addGuessMessage = (message: string) =>
    setGuessMessages((prev) => [...prev, message]);

  const submitGuess = () => {
    if (!post) return;
    setGuessSubmitted(true);
    const lowerBound = post.asking_price * 0.95;
    const upperBound = post.asking_price * 1.05;
    let message;
    const actualPrice = extractNumberFromString(price);
    const updatedAttempts = [...attempts, actualPrice!];
    if (actualPrice! <= upperBound && actualPrice! >= lowerBound) {
      logGuesstClick({
        guessNumber: currentClueNum + 1,
        guessType: "won",
        postingAddress: post.address,
      });
      logEndGame({
        numGuesses: updatedAttempts.length,
        postingAddress: post.address,
      });
      updateUserHistory(updatedAttempts, "win");
      navigate("/results");
      return;
    } else if (currentClueNum >= post.post_hints.length - 1) {
      logGuesstClick({
        guessNumber: currentClueNum + 1,
        guessType: "low",
        postingAddress: post.address,
      });
      logEndGame({
        numGuesses: updatedAttempts.length,
        postingAddress: post.address,
      });
      updateUserHistory(updatedAttempts, "lose");
      navigate("/results");
      return;
    } else if (currentClueNum === post.post_hints.length - 2) {
      // one last guess remaining
      message = getLastGuessMessage(
        actualPrice! <= lowerBound ? "low" : "high"
      );
      logGuesstClick({
        guessNumber: currentClueNum + 1,
        guessType: actualPrice! <= lowerBound ? "low" : "high",
        postingAddress: post.address,
      });
    } else {
      message =
        actualPrice! <= lowerBound ? getTooLowMessage() : getTooHighMessage();
      logGuesstClick({
        guessNumber: currentClueNum + 1,
        guessType: actualPrice! <= lowerBound ? "low" : "high",
        postingAddress: post.address,
      });
    }

    setAttemptHistory(updatedAttempts);
    setAttempts(updatedAttempts);
    addGuessMessage(message);
    setPrice("");
    setShowAttempts(true);
  };

  const handlePriceChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = extractNumberFromString(evt.target.value) || "";
    setPrice(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  };

  const goBack = () => {
    setCurrentClueNumber(currentClueNum - 1);
  };
  const goForward = () => {
    setCurrentClueNumber(currentClueNum + 1);
  };

  const scrollToTop = () => {
    const options: ScrollToOptions = {
      left: 0,
      top: 0,
      behavior: "smooth",
    };
    window.scroll(options);
    containerRef.current?.scroll(options);
  };

  const returnToNextClue = () => {
    setCurrentClueNumber(attempts.length);
    scrollToTop();
  };

  // used for the "NEXT CLUE" button
  const handleNextClue = () => {
    setGuessSubmitted(false);
    setShowAttempts(false);
    setCurrentClueNumber(currentClueNum + 1);
    scrollToTop();
  };

  return (
    <Box
      ref={containerRef}
      sx={(theme) => ({
        backgroundColor: theme.colors.emerald[2],
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: 0,
        overflowY: "auto",
        ".yarl__navigation_next": {
          opacity: 0,
        },
      })}
    >
      <Box sx={{ marginBottom: 30, flex: 1 }}>
        {zoom && (
          <Lightbox
            image={post?.post_hints[currentClueNum]?.image_url}
            allowRotate={false}
            onClose={() => setZoom(false)}
          />
        )}
        <Box
          sx={(theme) => ({
            position: "absolute",
            top: 20,
            left: 16,
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              display: "none",
            },
          })}
        >
          <Image src={Logo} alt="Housle" width={172} />
        </Box>
        <HowToPlay
          show={showInstructions}
          hideModal={() => setShowInstructions(false)}
        />
        <Center h="100%" sx={{ maxHeight: 900 }}>
          {post ? (
            <Stack
              w={425}
              h="90%"
              justify="space-between"
              spacing={0}
              sx={(theme) => ({
                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                  height: "100%",
                },
              })}
            >
              <Image
                src={Logo}
                alt="Housle"
                width={75}
                sx={(theme) => ({
                  display: "none",
                  padding: "0 8px",
                  [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                    display: "block",
                  },
                })}
              />
              <Flex
                h="100%"
                w="100%"
                direction={"column"}
                justify={"space-between"}
                align="center"
                sx={{ position: "relative" }}
              >
                <Container
                  p="sm"
                  w={"100%"}
                  bg={
                    zoom
                      ? "linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,  rgba(0,212,255,0) 100%)"
                      : "transparent"
                  }
                >
                  <Flex justify={"space-between"} gap={4}>
                    {[0, 1, 2, 3, 4, 5].map((i: number) => (
                      <Box
                        key={`Cluebar-${i}`}
                        sx={(theme) => ({
                          height: 6,
                          width: i === currentClueNum ? "28%" : "14%",
                          borderRadius: 20,
                          backgroundColor:
                            i === currentClueNum
                              ? theme.colors.poppy[0]
                              : theme.colors.cream[0],
                          transition: "all 0.5s ease-in-out",
                        })}
                      />
                    ))}
                  </Flex>
                  <Space h="xs" />
                  <Flex justify={"space-between"}>
                    <Text
                      size={20}
                      weight={800}
                      sx={(theme) => ({
                        color: theme.colors.poppy[0],
                        letterSpacing: 1,
                      })}
                    >{`CLUE #${currentClueNum + 1}`}</Text>
                    <Image
                      src={Info}
                      alt="more info"
                      height={30}
                      width={30}
                      onClick={() => setShowInstructions(true)}
                    />
                  </Flex>
                  <Text
                    size={20}
                    color="#fff"
                    weight={600}
                    sx={(theme) => ({
                      whiteSpace: "pre-wrap",
                      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        fontSize: 18,
                      },
                    })}
                  >
                    {post?.post_hints[currentClueNum]?.hint_text?.join("\n")}{" "}
                  </Text>
                </Container>
                {/* <TransformWrapper initialScale={1}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <ZoomComponent
                      isZoomedIn={zoom}
                      zoomIn={zoomIn}
                      zoomOut={zoomOut}
                      toggleZoom={toggleZoom}
                    />
                    <TransformComponent>
                    </TransformComponent>
                    </React.Fragment>
                    )}
                  </TransformWrapper> */}
                <ZoomComponent
                  isZoomedIn={zoom}
                  zoomIn={() => setZoom(true)}
                  zoomOut={() => setZoom(false)}
                  toggleZoom={toggleZoom}
                />
                <Image
                  src={post?.post_hints[currentClueNum]?.image_url}
                  alt="home image"
                />

                {attempts.length > 0 && (
                  <AttemptDrawer
                    attempts={attempts}
                    asking_price={post.asking_price}
                    show={showAttempts}
                    toggleShow={toggleShowAttempts}
                    currentClueNum={currentClueNum}
                    showSingleCard={
                      currentClueNum < attempts.length && !guessSubmitted
                    }
                  />
                )}
                <div />
              </Flex>
              {/*  */}
              <Flex
                direction={"column"}
                gap={12}
                sx={(theme) => ({
                  borderRadius: "0 0 12px 12px",
                  padding: 24,
                  backgroundColor: theme.colors.white[0],
                })}
              >
                {guessMessages[currentClueNum] ? (
                  <Text
                    size={18}
                    weight={600}
                    align="center"
                    sx={(theme) => ({ color: theme.colors.emerald[0] })}
                  >
                    {guessMessages[currentClueNum]}
                  </Text>
                ) : (
                  <TextInput
                    placeholder="Enter a Price"
                    value={price}
                    inputMode="numeric"
                    onChange={handlePriceChange}
                    onKeyUp={(e) =>
                      e.code === "Enter" && !!price && submitGuess()
                    }
                    icon={
                      <Text
                        size={24}
                        sx={(theme) => ({ color: theme.colors.emerald[2] })}
                      >
                        $
                      </Text>
                    }
                    sx={(theme) => ({
                      input: {
                        color: theme.colors.emerald[0],
                        fontSize: 24,
                        textAlign: "center",
                        height: 48,
                        webkitBoxShadow: "inset 0 0 5px #a6a6a6",
                        moxBoxShadow: "inset 0 0 5px #a6a6a6",
                        boxShadow: "inset 0 0 5px #a6a6a6",
                        "::placeholder": {
                          fontSize: 18,
                          color: theme.colors.emerald[0],
                        },
                      },
                    })}
                  />
                )}
                {guessSubmitted ? (
                  <Button
                    w="100%"
                    h={48}
                    onClick={handleNextClue}
                    sx={{
                      letterSpacing: 2,
                      textTransform: "uppercase",
                      fontSize: 20,
                    }}
                  >
                    NEXT CLUE
                  </Button>
                ) : (
                  <Flex gap={8}>
                    <Button
                      disabled={!currentClueNum}
                      onClick={goBack}
                      h={48}
                      w={48}
                      sx={(theme) => ({
                        border: `2px solid ${theme.colors.emerald[2]}`,
                        aspectRatio: "1/1",
                        backgroundColor: "transparent",
                        padding: 0,
                        "&:disabled": {
                          border: `2px solid ${theme.colors.emerald[1]}`,
                          backgroundColor: "transparent",
                          div: {
                            opacity: 0.75,
                            filter: "brightness(1.2)",
                          },
                        },
                        ":hover:enabled": {
                          backgroundColor: "transparent",
                        },
                      })}
                    >
                      <Image src={ChevronLeft} height={21} width={12} />
                    </Button>
                    {guessMessages[currentClueNum] ? (
                      <Button
                        w="100%"
                        h={48}
                        onClick={returnToNextClue}
                        sx={{
                          letterSpacing: 2,
                          textTransform: "uppercase",
                          fontSize: 20,
                        }}
                      >
                        BACK TO CLUE
                      </Button>
                    ) : (
                      <Button
                        w="100%"
                        h={48}
                        onClick={submitGuess}
                        sx={{
                          letterSpacing: 2,
                          textTransform: "uppercase",
                          fontSize: 20,
                        }}
                        disabled={!price}
                      >
                        GUESS
                      </Button>
                    )}
                    <Button
                      disabled={
                        currentClueNum === attempts.length ||
                        currentClueNum === post?.post_hints?.length
                      }
                      onClick={goForward}
                      h={48}
                      w={48}
                      sx={(theme) => ({
                        border: `2px solid ${theme.colors.emerald[2]}`,
                        aspectRatio: "1/1",
                        backgroundColor: "transparent",
                        padding: 0,
                        "&:disabled": {
                          border: `2px solid ${theme.colors.emerald[1]}`,
                          backgroundColor: "transparent",
                          div: {
                            opacity: 0.75,
                            filter: "brightness(1.2)",
                          },
                        },
                        ":hover:enabled": {
                          backgroundColor: "transparent",
                        },
                      })}
                    >
                      <Image
                        src={ChevronLeft}
                        height={21}
                        width={12}
                        sx={{ rotate: "180deg" }}
                      />
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Stack>
          ) : (
            <Loader color="orange" />
          )}
        </Center>
      </Box>
      <AdsComponent />
    </Box>
  );
};

export { Game };
