import { Box, Flex, Image } from "@mantine/core";
import React from "react";
import { AttemptCard } from "./AttemptCard";
import ChevronUp from "../images/chevron-up.png";
import Bump from "../images/bump.svg";

interface AttemptDrawerProps {
  attempts: number[];
  asking_price: number;
  show: boolean;
  toggleShow: () => void;
  currentClueNum: number;
  showSingleCard: boolean;
}

const BUMP_HEIGHT = 26;
const CARD_HEIGHT = 69.5;

export const AttemptDrawer: React.FC<AttemptDrawerProps> = ({
  attempts,
  asking_price,
  show,
  toggleShow,
  currentClueNum,
  showSingleCard,
}) => {
  return (
    <Flex
      direction={"column"}
      // justify="flex-en"
      w="100%"
      h={
        show
          ? BUMP_HEIGHT + CARD_HEIGHT * attempts.length
          : showSingleCard
          ? CARD_HEIGHT
          : BUMP_HEIGHT
      }
      sx={{
        position: "absolute",
        bottom: 0,
        overflow: "hidden",
        zIndex: 10,
        cursor: "pointer",
        transition: "height 0.5s ease-in-out",
      }}
    >
      <Box>
        {!showSingleCard ? (
          <>
            <Flex justify={"flex-end"} sx={{ borderBottom: "4px solid #fff" }}>
              <Image
                src={Bump}
                alt={"bump"}
                width={72}
                fit={"contain"}
                height={22}
                sx={{ position: "relative", right: 24 }}
                onClick={toggleShow}
              />
              <Image
                src={ChevronUp}
                alt={"^"}
                w={8}
                h={8}
                width={12}
                sx={{
                  position: "relative",
                  top: 8,
                  right: 66,
                  rotate: show ? "180deg" : "0deg",
                  transition: "rotate 0.5s ease-in-out",
                }}
                onClick={toggleShow}
              />
            </Flex>
            {attempts.map((attempt, i) => (
              <AttemptCard
                key={`attempt-${i}`}
                idx={i}
                amount={attempt}
                tooLow={attempt < asking_price}
              />
            ))}
          </>
        ) : (
          <AttemptCard
            idx={currentClueNum}
            amount={attempts[currentClueNum]}
            tooLow={attempts[currentClueNum] < asking_price}
          />
        )}
      </Box>
    </Flex>
  );
};
