import { NavLink } from "react-router-dom";
import { Anchor, Flex, FlexProps } from "@mantine/core";

type FooterProps = FlexProps;

const Footer = ({ sx, ...props }: FooterProps) => {
  return (
    <Flex
      align="center"
      justify="center"
      gap={24}
      wrap="wrap"
      sx={{
        ...sx,
        a: {
          color: "#fff",
          textDecoration: "none",
          cursor: "pointer",
        },
      }}
      {...props}
    >
      <NavLink to="/terms">Terms and Conditions</NavLink>
      <NavLink to="/privacy">Privacy Policy</NavLink>
      <Anchor href="mailto:info@housle.house">Contact Support</Anchor>
    </Flex>
  );
};

export default Footer;
