import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Center, Loader } from "@mantine/core";
import OverallStats from "components/OverallStats";
import ResultStats from "components/ResultStats";
import Footer from "components/Footer";
import { Gallery } from "components/Gallery";
import { useGame } from "hooks/useGame";
import { useLocalStorage } from "hooks/useLocalStorage";

export const Results = () => {
  const navigate = useNavigate();
  const { post, resultBanner } = useGame();
  const { history, completedToday } = useLocalStorage();
  const [showGallery, setShowGallery] = useState(false);

  const openGallery = () => setShowGallery(true);

  const closeGallery = () => setShowGallery(false);

  useEffect(() => {
    if (!completedToday) {
      navigate("/");
    }
  }, [completedToday, navigate]);

  return (
    <Box
      py={16}
      sx={(theme) => ({
        backgroundColor: theme.colors.emerald[0],
        height: "100vh",
        overflow: "auto",
      })}
    >
      <Center
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          margin: "0 auto",
          maxWidth: 450,
        }}
      >
        {post ? (
          <Box>
            <ResultStats history={history} post={post} />
            <OverallStats
              history={history}
              openGallery={openGallery}
              post={post}
              resultBanner={resultBanner}
            />
          </Box>
        ) : (
          <Loader color="orange" />
        )}
        <Footer sx={{ fontSize: 12, marginTop: 20 }} />
      </Center>

      {showGallery && <Gallery post={post} closeGallery={closeGallery} />}
    </Box>
  );
};

export default Results;
