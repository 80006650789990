export const USER_HISTORY = "user_history";
export const TOO_HIGH_MESSAGES = [
  "You guessed too high. Try again!",
  "Not quite that expensive - guess lower.",
  "That’s too high! Guess lower.",
  "Too high! Guess lower next time.",
  "Oooh too high! Keep trying. ",
  "Too high! Guess again.",
  "Good try but too high. Give it another shot.",
  "You overshot it. Guess lower!",
  "Tough luck, too high. Give it another try.",
  "Bummer, too high. Give it another go!",
  "Bummer! Too high. Try again.",
  "Sorry, that’s too high. Keep guessing!",
  "That guess is too high. Try going lower!",
  "Nope. Guess lower next time. ",
  "Not this time…lower guess welcome :)",
  "Unfortunately not this time. Too high - guess again. ",
  "Oops, you guessed too high. Go again.",
  "You miss 100 percent of the guesses you never take. Try again - lower!",
  "Those who dare to fail miserably can achieve greatly. Guess lower. ",
  "When life gives you lemons, guess lower.",
  "Never let the fear of striking out keep you from playing the game. Guess lower. ",
  "Go confidently in the direction of your guesses. Next time, lower. ",
  "Fortune favors those with a lower guess. Try again. ",
  "May you guess all the days of your life, but guess lower today. ",
  "Don't be afraid to give up a good guess to go for a great guess, one that’s lower. ",
  "You are what you guess. Guess again, lower this time. ",
  "Knowledge is power. Lower guess please.  ",
  "You’ll be back! Next time with a lower guess.",
  "Negative Ghostrider. The price is lower. ",
  "Make them a guess they can’t refuse, but make it lower next time. ",
  "There’s no place like Housle, there’s no place like Housle…but guess lower. ",
  "Carpe Diem. Seize the day! Make your lives extraordinary and your guess lower. ",
  "You’re gonna need a bigger boat, and a lower guess.  ",
  "Houston, we have a problem. Your guess is too high. Please guess lower. ",
  "There’s no crying in Housle. Guess lower. ",
  "Sorry. E.T., phone home with a lower guess.",
  "If you guess it they will come. Guess lower. ",
  "What we’ve got here is a failure to guess right! Try again, lower. ",
  "Housle. James Housle. Double O guess Lower. ",
  "Nobody puts a correct guess in a corner. Try again, lower. ",
  "Inconceivable! You guessed too high. Try again, lower. ",
  "Alright Mr. Demille. I’m ready for another guess. Try lower. ",
  "You’re killing me with this guess smalls! Guess lower next time. ",
  "They may take our guesses, but they’ll never take…Our Freedom! Guess lower. ",
  "Show me the guesses! Just guess lower. ",
  "You can’t handle the price! Well, maybe you can. But guess lower. ",
  "I’ve got the need, the need for a lower guess. ",
  "Hasta La Vista, guess. Try again, lower. ",
  "Housle is like a box of chocolates. You never know what to guess. Guess lower. ",
];

export const TOO_HIGH_LAST_GUESS_MESSAGES = [
  "Too high - one more guess!",
  "Too high - one guess left.",
  "Too high - one more chance! Guess lower!",
  "Too high. One more try - guess lower!",
  "Come on! You can do this! Last chance - guess lower!",
  "You over shot it, but you still have a chance! Last guess - lower this time.",
  "Your guess is too high. THIS IS IT! Nail it with this final guess!",
  "Too high, but this is your moment! Final guess - lock in!",
  "This is your moment! Own it. One final guess, lower please. ",
  "Tough times don’t last, tough people do. Go get the win with a lower guess. ",
  "Say ‘hello’ to my little friend - last guess! Lower please. ",
  "What is this? A guess for ants!? Guess lower please. ",
  "Not quite our tempo. Lower guess please. ",
  "King Kong ain’t got nothing on you. Last chance - guess lower!",
  "You live your life one guess at a time. Now guess lower for this last chance!",
  "Ma! More Meatloaf! I mean guesses. Last one - guess lower. ",
];

export const TOO_LOW_MESSAGES = [
  "Bummer! Too low. Try again.",
  "Sorry, that’s too low. Keep guessing!",
  "Tough one. Try going up!",
  "Nope. Guess higher next time.",
  "Not quite - guess higher.",
  "Too low! Guess again.",
  "Oops - not high enough. Go again.",
  "You guessed too low. Try again.",
  "It’s higher than that. Give it another stab. ",
  "Good try but too low. Give it another shot.",
  "That’s too low! Guess higher.",
  "Too low! Guess higher next time.",
  "Oooh too low! Keep trying.",
  "Good try but too low. Give it another shot.",
  "You undershot it. Guess higher!",
  "Tough luck, too low. Give it another try.",
  "Bummer, too low. Give it another go!",
  "Too low! Guess again.",
  "Unfortunately not this time. Too low - guess again. ",
  "You miss 100 percent of the guesses you never take. Try again - higher!",
  "Those who dare to fail miserably can achieve greatly. Guess higher. ",
  "When life gives you lemons, guess higher.",
  "Never let the fear of striking out keep you from playing the game. Guess higher. ",
  "Go confidently in the direction of your guesses. Next time, higher. ",
  "Fortune favors those with a higher guess. Try again. ",
  "May you guess all the days of your life, but guess higher today. ",
  "Don't be afraid to give up a good guess to go for a great guess, one that’s higher. ",
  "You are what you guess. Guess again, higher this time. ",
  "Knowledge is power. Higher guess please.  ",
  "You’ll be back! Next time with a higher guess.",
  "Negative Ghostrider. The price is higher. ",
  "Make them a guess they can’t refuse, but make it higher next time. ",
  "There’s no place like Housle, there’s no place like Housle…but guess higher. ",
  "Carpe Diem. Seize the day! Make your lives extraordinary and your guess higher. ",
  "You’re gonna need a bigger boat, and a higher guess.  ",
  "Houston, we have a problem. Your guess is too low. Please guess higher. ",
  "There’s no crying in Housle. Guess higher. ",
  "Sorry. E.T., phone home with a higher guess.",
  "If you guess it they will come. Guess higher. ",
  "What we’ve got here is a failure to guess right! Try again, higher. ",
  "Housle. James Housle. Double O guess higher. ",
  "Nobody puts a correct guess in a corner. Try again, higher. ",
  "Inconceivable! You guessed too low. Try again, higher. ",
  "Alright Mr. Demille. I’m ready for another guess. Try higher. ",
  "You’re killing me with this guess smalls! Guess higher next time. ",
  "They may take our guesses, but they’ll never take…Our Freedom! Guess higher. ",
  "Show me the guesses! Just guess higher. ",
  "You can’t handle the price! Well, maybe you can. But guess higher. ",
  "I’ve got the need, the need for a higher guess. ",
  "Hasta La Vista, guess. Try again, higher. ",
  "Housle is like a box of chocolates. You never know what to guess. Guess higher. ",
];

export const TOO_LOW_LAST_GUESS_MESSAGES = [
  "Too low - one more guess!",
  "Too low - one guess left.",
  "Too low - one more chance! Guess higher!",
  "One more try - guess higher!",
  "Come on! You can do this! Last chance - guess higher!",
  "You over shot it, but you still have a chance! Last guess - higher this time.",
  "Your guess is too low. THIS IS IT! Nail it with this final guess!",
  "Too low, but this is your moment! Final guess - lock in!",
  "This is your moment! Own it. One final guess, higher please. ",
  "Tough times don’t last, tough people do. Go get the win with a higher guess. ",
  "Say ‘hello’ to my little friend - last guess! Higher please. ",
  "What is this? A guess for ants!? Guess higher please. ",
  "Not quite our tempo. Higher guess please. ",
  "King Kong ain’t got nothing on you. Last chance - guess higher!",
  "You live your life one guess at a time. Now guess higher for this last chance!",
  "Ma! More Meatloaf! I mean guesses. Last one - guess higher. ",
];

export const LOSE_MESSAGES = [
  "Today’s Housle was a tough one!",
  "I couldn’t crack the Housle code!",
];

export const WIN_MESSAGES = [
  "You’re a Housle Tycoon!",
  "You’re a Housle Mogul!",
  "You’re a Housle Magnate!",
  "You’re a Housle Baron!",
  "You’re a Housle Legend!",
  "You’re a Housle Boss!",
];
