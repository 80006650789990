import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import CONFIG from "utils/config";

const firebaseConfig = {
  apiKey: CONFIG.FIREBASE.API_KEY,
  appId: CONFIG.FIREBASE.APP_ID,
  messagingSenderId: CONFIG.FIREBASE.MESSAGING_SENDER_ID,
  projectId: CONFIG.FIREBASE.PROJECT_ID,
  authDomain: CONFIG.FIREBASE.AUTH_DOMAIN,
  storageBucket: CONFIG.FIREBASE.STORAGE_BUCKET,
  measurementId: CONFIG.FIREBASE.MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export { db, analytics };
