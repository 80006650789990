import React, { useMemo } from "react";
import { usePost } from "hooks/usePost";
import { usePartner } from "hooks/usePartner";
import { AdsBanner, Post, ResultBanner } from "types/common";

export type GameContextType = {
  adsBanner: AdsBanner | null;
  post: Post | null;
  resultBanner: ResultBanner | null;
};

export const GameContext = React.createContext({} as GameContextType);

type GameProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};

const GameProvider = ({ children }: GameProviderProps) => {
  const { post } = usePost();
  const { partner } = usePartner(post?.partner);

  const resultBanner = useMemo(() => {
    if (!post?.banner) return null;
    return {
      ...post.banner,
      background: post.post_hints[+post.banner.background].image_url,
      foreground: post.post_hints[+post.banner.foreground].image_url,
      logo: partner?.logo,
    };
  }, [post, partner?.logo]);

  const adsBanner = useMemo(() => {
    if (!partner) return null;
    return {
      image: partner.banner,
      link: partner.bannerUrl,
    };
  }, [partner]);

  return (
    <GameContext.Provider
      value={{
        adsBanner,
        post,
        resultBanner,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export default GameProvider;
