import { Flex, Image, Text } from "@mantine/core";
import React from "react";
import ZoomIn from "../images/zoom-in.png";
import ZoomOut from "../images/zoom-out.png";

interface ZoomProps {
  isZoomedIn: boolean;
  zoomIn: Function;
  zoomOut: Function;
  toggleZoom: () => void;
}

export const ZoomComponent: React.FC<ZoomProps> = ({ zoomIn, isZoomedIn, zoomOut, toggleZoom }) => {
  return (
    <Flex
      align="center"
      justify={"center"}
      gap={8}
      p={"4px 12px"}
      onClick={() => {
        toggleZoom();
        isZoomedIn ? zoomOut() : zoomIn()
      }}
      sx={(theme) => ({
        position: "absolute",
        bottom: 32,
        borderRadius: 32,
        backgroundColor: theme.colors.poppy[0],
        cursor: "pointer",
        zIndex: 5
      })}
    >
      <Image
        src={isZoomedIn ? ZoomOut : ZoomIn}
        alt="zoom in"
        width={16}
        height={16}
      />
      <Text size={12} color="rgba(255,255,255, 0.8)" weight={800}>
        {isZoomedIn ? "ZOOM OUT" : "ZOOM IN"}
      </Text>
    </Flex>
  );
};
