import { Box, Button, Text, TextProps } from "@mantine/core";
import { useMessages } from "hooks/useMessages";
import { logShareScore } from "services/analytics";
import { History, Post } from "types/common";
import { HOUSLE_URL, generateMailto, generateNativeShare } from "utils/misc";

type ResultStatsProps = {
  history: History;
  post: Post;
};

type PriceTextProps = {
  title: string;
  price: string;
} & TextProps;

const PriceText = ({ title, price, ...props }: PriceTextProps) => {
  return (
    <Text
      size={18}
      sx={(theme) => ({
        lineHeight: "20px",
        span: {
          color: theme.colors.poppy[0],
          display: "inline-block",
          fontSize: 14,
          fontWeight: 600,
          lineHeight: "16px",
          textAlign: "right",
          width: 84,
        },
      })}
      {...props}
    >
      <span>{title}</span> ${price}
    </Text>
  );
};

const ResultStats = ({ history, post }: ResultStatsProps) => {
  const { getLoseMessage, getWinMessage } = useMessages();
  const { currentStreak, attempts } = history;

  const userWon = currentStreak > 0;
  const winLosemessage = userWon
    ? getWinMessage(attempts.length)
    : getLoseMessage();

  const listPrice = post.asking_price;

  const bestGuess = attempts.reduce(
    (acc, curr) =>
      Math.abs(listPrice - curr) < Math.abs(listPrice - acc) ? curr : acc,
    0
  );

  const absolutePercentageDiff = Math.abs(
    ((bestGuess - listPrice) / listPrice) * 100
  );

  const openMail = () => {
    window.location.href = generateMailto({
      attempts,
      post,
      winLosemessage,
    });
  };

  const handleShare = () => {
    try {
      logShareScore();
      if (navigator.share) {
        navigator
          .share({
            title: "Housle Results",
            text: generateNativeShare({ attempts, post, winLosemessage }),
            url: HOUSLE_URL,
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(console.error);
      } else {
        // Fallback
        openMail();
      }
    } catch (e) {
      openMail();
    }
  };

  return (
    <Box
      sx={{
        color: "white",
        fontWeight: 500,
        padding: "24px 32px",
        textAlign: "center",
      }}
    >
      <Text mb={16} size={28} sx={{ lineHeight: "26px" }}>
        {winLosemessage}
      </Text>

      <Text mb={12} size={20}>
        {userWon ? (
          <Text>
            You won in{" "}
            <Box
              sx={(theme) => ({
                display: "inline",
                padding: "4px 9px",
                borderRadius: 4,
                textAlign: "center",
                backgroundColor: theme.colors.poppy[0],
              })}
            >
              {attempts.length}
            </Box>{" "}
            guess{attempts.length > 1 ? "es" : ""}.
          </Text>
        ) : (
          "You lost this round."
        )}
      </Text>

      <PriceText
        mb={8}
        title="List Price:"
        price={listPrice.toLocaleString()}
      />
      <PriceText
        mb={12}
        title="Your Guess:"
        price={bestGuess.toLocaleString()}
      />

      <Box
        mb={24}
        sx={(theme) => ({
          display: "inline-flex",
          padding: "2px 16px",
          borderRadius: "500px",
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid ${theme.colors.poppy[0]}`,
          alignSelf: "center",
          justifySelf: "center",
          fontSize: 12,
        })}
      >
        <Text color="poppy.0" sx={{ marginRight: 4 }}>
          {absolutePercentageDiff.toFixed(2)}%{" "}
        </Text>{" "}
        <Text> from the exact price</Text>
      </Box>

      <Button
        h={40}
        w={"100%"}
        sx={(theme) => ({
          backgroundColor: theme.colors.poppy[0],
          fontSize: 14,
          letterSpacing: 2.5,
          lineHeight: "24px",
          padding: "8px 40px",
          textTransform: "uppercase",
          ":hover:enabled": {
            backgroundColor: theme.colors.poppy[0],
          },
        })}
        onClick={handleShare}
      >
        Share Results
      </Button>
    </Box>
  );
};

export default ResultStats;
