import { useState } from "react";
import {
  TOO_HIGH_MESSAGES,
  TOO_HIGH_LAST_GUESS_MESSAGES,
  TOO_LOW_MESSAGES,
  TOO_LOW_LAST_GUESS_MESSAGES,
  WIN_MESSAGES,
  LOSE_MESSAGES,
} from "../utils/static";

export const useMessages = () => {
  const [highMessages, setHighMessages] = useState(TOO_HIGH_MESSAGES);
  const [lowMessages, setLowMessages] = useState(TOO_LOW_MESSAGES);

  const getTooHighMessage = () => {
    const totalMessages = TOO_HIGH_MESSAGES.length;
    const randomIdx = Math.floor(Math.random() * totalMessages);

    const newMessages = highMessages;
    const randomMessage = newMessages.splice(randomIdx, 1);
    setHighMessages(newMessages);
    return randomMessage[0];
  };

  const getTooLowMessage = () => {
    const totalMessages = TOO_LOW_MESSAGES.length;
    const randomIdx = Math.floor(Math.random() * totalMessages);

    const newMessages = lowMessages;
    const randomMessage = newMessages.splice(randomIdx, 1);
    setLowMessages(newMessages);
    return randomMessage[0];
  };

  const getLastGuessMessage = (type: "high" | "low") => {
    const totalMessages =
      type === "high"
        ? TOO_HIGH_LAST_GUESS_MESSAGES.length
        : TOO_LOW_LAST_GUESS_MESSAGES.length;
    const randomIdx = Math.floor(Math.random() * totalMessages);

    return type === "high"
      ? TOO_HIGH_LAST_GUESS_MESSAGES[randomIdx]
      : TOO_LOW_LAST_GUESS_MESSAGES[randomIdx];
  };

  const getWinMessage = (numGuesses: number) => WIN_MESSAGES[numGuesses - 1];

  const getLoseMessage = () => {
    const totalMessages = LOSE_MESSAGES.length;
    const randomIdx = Math.floor(Math.random() * totalMessages);
    return LOSE_MESSAGES[randomIdx];
  };

  return {
    getTooLowMessage,
    getTooHighMessage,
    getLastGuessMessage,
    getWinMessage,
    getLoseMessage,
  };
};
