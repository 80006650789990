import { MantineProvider } from "@mantine/core";
import GameProvider from "contexts/GameContext";
import AppRouter from "./AppRouter";
import { theme } from "./theme";

const App = () => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <GameProvider>
        <AppRouter />
      </GameProvider>
    </MantineProvider>
  );
};

export default App;
