import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Landing } from "pages/Landing";
import { Game } from "pages/Game";
import { Terms } from "pages/Terms";
import { Privacy } from "pages/Privacy";
import { Results } from "pages/Results";
import { useEffect } from "react";

const routes = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/game",
    element: <Game />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/results",
    element: <Results />,
  },
];

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
      <ScrollToTop />
    </Router>
  );
};

export default AppRouter;
