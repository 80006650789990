import React from "react";
import { Modal, Flex, Title, Image, Text, Stack } from "@mantine/core";
import Close from "../images/close.png";

interface HowToPlayProps {
  show: boolean;
  hideModal: () => void;
}

export const HowToPlay: React.FC<HowToPlayProps> = ({ show, hideModal }) => {
  return (
    <Modal
      centered
      opened={show}
      onClose={hideModal}
      padding={0}
      radius={16}
      withCloseButton={false}
    >
      <Flex
        justify={"space-between"}
        align="center"
        sx={(theme) => ({
          padding: "8px 12px",
          borderRadius: "15px 15px 0 0",
          backgroundColor: theme.colors.emerald[0],
        })}
      >
        <Title
          order={3}
          size={18}
          weight={500}
          sx={(theme) => ({
            color: theme.colors.white[0],
            textTransform: "uppercase",
          })}
        >
          HOW TO PLAY
        </Title>
        <Image src={Close} height={20} width={20} onClick={hideModal} />
      </Flex>
      <Stack p={12}>
        <div>
          <Title order={2} size={16}>
            THE BASICS
          </Title>
          <Text align="left">
            Guess the asking price of a house within six tries. With each
            incorrect attempt, you'll learn if you guessed too high or too low,
            and then receive a new photo and clue.
          </Text>
          <Text align="left" sx={{ marginTop: 16 }}>
            If you guess within 5% of the list price, you win!
          </Text>
        </div>
        <div>
          <Title order={2} size={16}>
            SHARE YOUR SCORE
          </Title>
          <Text align="left">
            Share your score with friends and keep track of your stats.
          </Text>
        </div>
      </Stack>
    </Modal>
  );
};
