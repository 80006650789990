import { Box, Button, Flex, Image, Stack, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Close from "../images/close.png";
import Logo from "../images/housle_logo.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import "./Gallery.css";
//@ts-ignore
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { Hint } from "types/common";
import { logListingLinkClick } from "../services/analytics";
import AdsComponent from "./AdsComponent";

interface GalleryProps {
  post: any;
  closeGallery: () => void;
}

export const Gallery: React.FC<GalleryProps> = ({ post, closeGallery }) => {
  const [images, setImages] = useState([]);
  const [showLightbox, setShowLightbox] = useState(false);
  const [startIdx, setStartIdx] = useState(0);

  useEffect(() => {
    if (!post) return;
    setImages(post.post_hints.map((hint: Hint) => hint.image_url));
  }, [post]);

  const handleClickImage = (index: number) => {
    setShowLightbox(true);
    setStartIdx(index);
  };

  return (
    <Stack
      w={"100vw"}
      h={"100vh"}
      bg={"#fff"}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 16,
        zIndex: 100,
      }}
    >
      {showLightbox && (
        <Lightbox
          images={images.map((url) => ({ url }))}
          allowRotate={false}
          onClose={() => setShowLightbox(false)}
          startIndex={startIdx}
        />
      )}
      <Flex align={"center"} justify="space-between">
        <Box
          sx={(theme) => ({
            background: theme.colors.emerald[0],
            padding: "8px 16px",
            borderRadius: 16,
          })}
        >
          <Image
            src={Logo}
            width={172}
            sx={(theme) => ({
              cursor: "pointer",
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                width: 100,
              },
            })}
          />
        </Box>
        <Flex
          align={"center"}
          gap={16}
          onClick={closeGallery}
          sx={(theme) => ({
            cursor: "pointer",
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              fontSize: 12,
              gap: 8,
            },
          })}
        >
          <Text
            size={28}
            sx={(theme) => ({
              color: theme.colors.poppy[0],
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                fontSize: 16,
              },
            })}
          >
            Back to Results
          </Text>
          <Image src={Close} width={32} height={32} />
        </Flex>
      </Flex>
      <Stack h={"100%"} align={"center"} justify="space-around">
        {/* DESKTOP SLIDER */}
        <Stack
          w={"50%"}
          align={"center"}
          justify="space-around"
          sx={(theme) => ({
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              display: "none",
            },
          })}
        >
          <Swiper
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {images.map((url, i) => (
              <SwiperSlide key={url}>
                <Image
                  src={url}
                  width={500}
                  onClick={() => handleClickImage(i)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Stack>
        {/* MOBILE SLIDER */}
        <Box
          sx={(theme) => ({
            display: "none",
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              display: "block",
            },
          })}
        >
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={20}
            // navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {images.map((url, i) => (
              <SwiperSlide key={url}>
                <Image
                  src={url}
                  width={"100%"}
                  onClick={() => handleClickImage(i)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Flex direction={"column"} align={"center"} gap={16}>
          <Text
            size={20}
            align="center"
            sx={(theme) => ({ color: theme.colors.emerald[0] })}
          >
            {post.address}
          </Text>
          {post.listing_link_sovrn ? (
            <a
              href={post.listing_link_sovrn}
              target={"_blank"}
              rel="noreferrer noopener"
            >
              <Button
                h={48}
                sx={(theme) => ({
                  fontSize: 20,
                  textTransform: "uppercase",
                  backgroundColor: theme.colors.poppy[0],
                  ":hover:enabled": {
                    backgroundColor: theme.colors.poppy[0],
                  },
                })}
                onClick={logListingLinkClick}
              >
                VIEW LISTING
              </Button>
            </a>
          ) : null}
        </Flex>
      </Stack>
      <AdsComponent />
    </Stack>
  );
};
