import { Box, Container, Flex, Text } from "@mantine/core";
import React from "react";

interface AttemptCardProps {
  idx: number;
  amount: number;
  tooLow: boolean;
}

export const AttemptCard: React.FC<AttemptCardProps> = ({
  idx,
  amount,
  tooLow,
}) => {
  const formatAmount = () => "$" + amount.toLocaleString();
  return (
    <Container
      sx={(theme) => ({
        backgroundColor: theme.colors.cream[0],
        borderBottom: "1px solid #fff",
      })}
    >
      <Flex align={"center"} justify={"space-between"} p={"xs"}>
        <Box>
          <Text weight={800} sx={(theme) => ({ color: theme.colors.poppy[0] })}>
            GUESS {idx + 1}
          </Text>
          <Text
            sx={(theme) => ({
              color: theme.colors.emerald[0],
            })}
          >
            {formatAmount()}
          </Text>
        </Box>
        <Text
          size={14}
          weight={600}
          sx={(theme) => ({
            borderRadius: 20,
            padding: "4px 12px",
            backgroundColor: tooLow
              ? theme.colors.emerald[1]
              : theme.colors.blush[0],
            color: theme.colors.emerald[0],
          })}
        >
          {tooLow ? "TOO LOW" : "TOO HIGH"}
        </Text>
      </Flex>
    </Container>
  );
};
