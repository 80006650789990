import mixpanel from "mixpanel-browser";

mixpanel.init("e49397304dd2fe23b6884690b5b8d3dc", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

const identifyUserMixPanel = ({ email }: { email: string }) => {
  // Set this to a unique identifier for the user performing the event.
  mixpanel.identify(email);
};

export { identifyUserMixPanel, mixpanel };
