import { logEvent } from "firebase/analytics";
import { analytics } from "./firestore";
import { mixpanel } from "./mixpanel";

const trackEvent = (event_name: string, event_params: object = {}) => {
  try {
    logEvent(analytics, event_name, { ...event_params, source: "web" });
    const formattedEventName = event_name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
    mixpanel.track(formattedEventName, { ...event_params, source: "web" });
  } catch (err) {
    console.error("Log Event failed: ", err);
  }
};
enum Guess {
  high = "high",
  low = "low",
  won = "won",
}

export type GuessType = keyof typeof Guess;

export const logSessionStartWeb = () => trackEvent("session_start_web");
export const logShareScore = () =>
  trackEvent("share", { reason: "share score" });
export const logListingLinkClick = () =>
  trackEvent("select_content", { content_type: "listingLink" });
export const logSimilarListingClick = () =>
  trackEvent("select_content", { content_type: "similarListingsLink" });
export const logStartGame = ({
  posting_address,
}: {
  posting_address: string;
}) => trackEvent("start_game", { content_type: "click", posting_address });
export const logEndGame = ({
  numGuesses,
  postingAddress,
}: {
  numGuesses: number;
  postingAddress: string;
}) =>
  trackEvent("end_game", {
    content_type: "click",
    numGuesses,
    postingAddress,
  });
export const logGuesstClick = ({
  guessNumber,
  guessType,
  postingAddress,
}: {
  guessNumber: number;
  guessType: GuessType;
  postingAddress: string;
}) =>
  trackEvent(`guess_${guessNumber}`, {
    content_type: "click",
    guessType,
    postingAddress,
  });
