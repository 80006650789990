import React, { useEffect, useState } from "react";
import { Adsense } from "@ctrl/react-adsense";
import { Flex } from "@mantine/core";

declare global {
  interface Window {
    adsbygoogle?: Array<any>;
  }
}

const MOBILE_AD_SLOT = "6628447945";
const DESKTOP_AD_SLOT = "5506937963";

const GoogleAds: React.FC = () => {
  const [isMobile, setIsMobile] = useState(true);
  const adSlot = isMobile ? MOBILE_AD_SLOT : DESKTOP_AD_SLOT;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Flex
      align={"center"}
      justify="center"
      key={adSlot}
      sx={{
        display: "inline-block",
        height: isMobile ? "50px" : "70px",
        margin: "0 auto",
        maxWidth: isMobile ? "320px" : "468px",
        width: "100%",
      }}
    >
      <Adsense client="ca-pub-5016536114852392" slot={adSlot} />
    </Flex>
  );
};

export default GoogleAds;
