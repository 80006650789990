import { Post } from "types/common";

const NATIVE_BREAK = "\n";
const BREAK = "%0D%0A";
const MAX_NUM_ATTEMPTS = 6;
export const HOUSLE_URL = "https://housle.house/";

function calcDifference(num1: number, num2: number) {
  const percentage = (num1 / num2 - 1) * 100;
  return percentage.toFixed(1);
}

export const extractNumberFromString = (value: string) => {
  return parseFloat(value.replace(/[^\d.-]/g, ""));
};

type GenerateShareParams = {
  winLosemessage: string;
  attempts: number[];
  post: any;
};

export const generateNativeShare = ({
  winLosemessage,
  attempts,
  post,
}: GenerateShareParams) => {
  const body =
    winLosemessage.replace("You’re", "I'm") +
    ` ${attempts.length}/${MAX_NUM_ATTEMPTS}` +
    NATIVE_BREAK.repeat(2);
  const guesses = attempts.reduce((acc, curr, i) => {
    const houses = "🏠".repeat(i + 1) + " ";
    const diff = post ? calcDifference(curr, post.asking_price) + "%" : "";
    return acc + houses + diff + NATIVE_BREAK;
  }, "");
  return body + guesses;
};

export const generateMailto = ({
  winLosemessage,
  attempts,
  post,
}: GenerateShareParams) => {
  const subject = "Let's play Housle!";
  const body =
    HOUSLE_URL +
    BREAK.repeat(2) +
    winLosemessage.replace("You’re", "I'm") +
    ` ${attempts.length}/${MAX_NUM_ATTEMPTS}` +
    BREAK.repeat(2);
  const guesses = attempts.reduce((acc, curr, i) => {
    const houses = "🏠".repeat(i + 1) + " ";
    const diff = post ? calcDifference(curr, post.asking_price) + "%" : "";
    return acc + houses + diff + BREAK;
  }, "");
  return `mailto:?subject=${subject}&body=${body + guesses}`;
};

export const cacheHintImages = async (post: Post | null) => {
  if (!post) return;
  // remove first image because it will automatically be the first one loaded
  const { post_hints } = post;
  const urls = post_hints
    .slice(1, post_hints.length)
    .map((hint) => hint.image_url);
  const promises = await urls.map((src) => {
    return new Promise(function (resolve, reject) {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  });

  await Promise.all(promises);
};

export const formatPrice = (price: number) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return formatter.format(price);
};
