import * as React from "react";
import {
  AppShell,
  Button,
  Center,
  Flex,
  Stack,
  Title,
  Text,
  Divider,
  Image,
} from "@mantine/core";
import Footer from "components/Footer";
import AdsComponent from "components/AdsComponent";
import Background from "../images/housle_background_image.jpg";
import Logo from "../images/housle_logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { logSessionStartWeb } from "../services/analytics";

const Landing: React.FC = () => {
  const { completedToday, playedToday } = useLocalStorage();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (completedToday) {
      navigate("/results");
    } else if (!playedToday) {
      logSessionStartWeb();
    }
  }, [completedToday, navigate, playedToday]);

  return (
    <AppShell
      padding={0}
      sx={{
        backgroundBlendMode: "color",
        backgroundColor: "rgba(0,0,0,0.4)",
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Center sx={{ height: "100vh", marginBottom: 30 }}>
        <Stack
          w="100%"
          h="100vh"
          align="center"
          justify="space-around"
          p={16}
          sx={{ maxHeight: 850 }}
        >
          <Image
            src={Logo}
            alt="HOUSLE"
            // width={300}
            // height={87}
            sx={(theme) => ({
              width: "50%",
              maxWidth: 300,
            })}
          />
          <Stack
            w={400}
            // h={500}
            style={{
              background: "white",
              borderRadius: "10px",
              maxWidth: "100%",
            }}
            sx={(theme) => ({
              overflow: "auto",
              padding: "36px 24px",
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                maxHeight: "60%",
                padding: 24,
                justifyContent: "space-between",
              },
            })}
          >
            <Stack
              sx={(theme) => ({
                overflow: "auto",
                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                  // maxHeight: "%",
                },
              })}
            >
              <Flex justify={"center"} align={"center"} direction={"column"}>
                <Title
                  size={32}
                  sx={{
                    fontFamily: "Playfair Display, serif",
                    paddingBottom: 8,
                    textAlign: "center",
                  }}
                >
                  Guess the House Price
                </Title>
                <Text align="center">
                  Housle is a daily trivia game where you guess the list price
                  of houses!
                </Text>
              </Flex>
              <Divider variant="solid" />
              <div>
                <Title order={2} size={18}>
                  THE BASICS
                </Title>
                <Text align="left">
                  Guess the asking price of a house within six tries. With each
                  incorrect attempt, you'll learn if you guessed too high or too
                  low, and then receive a new photo and clue.
                </Text>
                <Text align="left" sx={{ marginTop: 16 }}>
                  If you guess within 5% of the list price, you win!
                </Text>
              </div>
              <div>
                <Title order={2} size={18}>
                  SHARE YOUR SCORE
                </Title>
                <Text align="left">
                  Share your score with friends and keep track of your stats
                </Text>
              </div>
            </Stack>
            <NavLink
              to="/game"
              style={{ width: "100%", color: "#fff", textDecoration: "none" }}
            >
              <Button
                h={48}
                w={"100%"}
                sx={(theme) => ({
                  minHeight: 48,
                  background: playedToday
                    ? theme.colors.poppy[0]
                    : theme.colors.emerald[0],
                  fontSize: 20,
                  letterSpacing: 2,
                })}
              >
                {playedToday ? "RESUME GAME" : "PLAY NOW!"}
              </Button>
            </NavLink>
          </Stack>
          <Footer
            sx={(theme) => ({
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                gap: 12,
              },
            })}
          />
        </Stack>
      </Center>
      <AdsComponent />
    </AppShell>
  );
};

export { Landing };
