import { Anchor, Container, List, Stack, Text, Title } from "@mantine/core";
import React from "react";
import { NavLink } from "react-router-dom";

export const Privacy = () => {
  return (
    <Container p={24}>
      <NavLink to="/" style={{ textDecoration: "none" }}>
        &lt; Back
      </NavLink>
      <Stack>
        <Title order={1}>PRIVACY POLICY</Title>
        <Title order={2}>Effective Date: OCTOBER 6, 2022</Title>
        <Text>
          At Housle, maintaining the trust of our online users is of the utmost
          importance to us. Therefore, we have developed this Privacy Policy
          (the "<b>Policy</b>") to show our commitment to fair information
          practices and the protection of our users' privacy, and to explain how
          we collect, process, use and share information about our users. This
          Policy applies to our company website, Site, and to all other websites
          and applications that are owned or operated by Housle, LLC or its
          subsidiaries (referred to collectively herein as "<b>Housle</b>" or "
          <b>us</b>" or "<b>we</b>") and that have this Policy posted
          (collectively, the "<b>Site</b>" or "<b>Sites</b>"). For purposes of
          this Policy, personal information shall mean any information relating
          to an identiﬁed or identiﬁable natural person.
        </Text>
        <Title order={2}>NOTICE CONCERNING CHILDREN</Title>
        <Text>
          The Sites are not speciﬁcally directed at or intended for children
          under the age of 16. Therefore, Housle does not knowingly collect
          personal information via the Sites from anyone under 16. Any user that
          believes that Housle has in its database the personal information of a
          child under the age of 16 should contact us using the email or postal
          address set forth under "Contact Information" below, so that, if
          necessary, Housle can have such information removed.
        </Text>
        <Title order={2}>Website Visitors</Title>
        <Text>
          The Sites collect non-personally-identifying information of the sort
          that web browsers and servers typically make available, such as the
          browser type, language preference, referring site, and the date and
          time of each visitor request. The Site's purpose in collecting
          non-personally identifying information is to better understand how the
          Site's visitors use its website. From time to time, the Sites may
          release non-personally-identifying information in the aggregate, e.g.,
          by publishing a report on trends in the usage of its website.
        </Text>
        <Text>
          The Sites also collect potentially personally-identifying information
          like Internet Protocol (IP) addresses for logged in users. The Sites
          only disclose logged in user and commenter IP addresses under the same
          circumstances that it uses and discloses personally-identifying
          information as described below.
        </Text>
        <Title order={2}>Security</Title>
        <Text>
          We take commercially reasonable eﬀorts to keep any
          personally-identifying information secure and we believe in the
          importance of security. However, when it comes to internet
          communication, no method is 100% secure or safe. Therefore, we cannot
          guarantee its absolute security.
        </Text>
        <Title order={2}>Links To External Sites</Title>
        <Text>
          The Sites may contain links to third-party websites and services,
          and/or display advertisements for third parties (collectively,
          "Third-Party Links & Ads"). Such Third-Party Links & Ads are not under
          our control and we are not responsible for any Third-Party Links &
          Ads. You should make whatever investigation you feel necessary or
          appropriate before proceeding with any\ transaction in connection with
          such Third-Party Links & Ads. We have no control over, and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites, products or services.
        </Text>
        <Title order={2}>Remarketing</Title>
        <Text>
          Our sites may use the remarketing services to advertise on third-party
          websites to previous visitors to our site. We may advertise to
          previous visitors of our Sites who have visited without interacting
          with any content on the Sites. Vendors who provide Third-Party Links &
          Ads use cookies to serve ads based on someone's past visits. See
          "Links to External Sites" above for more information on third-party
          ads and similar usage.
        </Text>
        <Title order={2}>SHARING OF PERSONAL INFORMATION</Title>
        <Text>We may share personal information as follows:</Text>
        <List>
          <List.Item>
            We may share personal information with your consent. For example,
            you may let us share personal information with others for their own
            marketing uses. Those uses will be subject to their privacy
            policies.
          </List.Item>
          <List.Item>
            We may share personal information when we do a business deal, or
            negotiate a business deal, involving the sale or transfer of all or
            a part of our business or assets. These deals can include any
            merger, financing, acquisition, or bankruptcy transaction or
            proceeding.
          </List.Item>
          <List.Item>
            We may share personal information for legal, protection, and safety
            purposes.
            <List>
              <List.Item>
                We may share information to comply with laws.
              </List.Item>
              <List.Item>
                We may share information to respond to lawful requests and legal
                processes.
              </List.Item>
              <List.Item>
                We may share information to protect the rights and property of
                Housle, LLC, our agents, customers, and others. This includes
                enforcing our agreements, policies, and terms of use.
              </List.Item>
              <List.Item>
                We may share information in an emergency. This includes
                protecting the safety of our employees and agents, our
                customers, or any person.
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            We may share information with those who need it to do work for us.
          </List.Item>
          <List.Item>
            We may also share aggregated and/or anonymized data with others for
            their own uses.
          </List.Item>
        </List>
        <Title order={2}>Aﬃliate Disclosure</Title>
        <Text>
          The Sites uses aﬃliate links and may earn a commission from certain
          links. This does not aﬀect your purchases or the price you may pay.
        </Text>
        <Title order={2}>Cookies</Title>
        <Text>
          We may log information using "Cookies." Cookies are small data ﬁles
          stored on your hard drive by a website. We may use both session
          Cookies (which expire once you close your web browser) and persistent
          Cookies (which stay on your computer until you delete them) to provide
          you with a more personal and interactive experience on our Site. This
          type of information is collected to make the Site more useful to you
          and to tailor the experience with us to meet your special interests
          and needs.
        </Text>
        <Text>
          You can typically remove and reject cookies from our Site with your
          browser settings. Many browsers are set to accept cookies until you
          change your settings. If you remove or reject our cookies, it could
          aﬀect how our Site works for you.
        </Text>
        <Title order={2}>Pixel Tags</Title>
        <Text>
          You can typically remove and reject cookies from our Site with your
          browser settings. Many browsers are set to accept cookies until you
          change your settings. If you remove or reject our cookies, it could
          aﬀect how our Site works for you.
        </Text>
        <Title order={2}>Do Not Track.</Title>
        <Text>
          Our Site does not support or respond to do-not-track signals. You may
          disable certain tracking as discussed above re:cookies.
        </Text>
        <Title order={2}>Privacy Policy Changes</Title>
        <Text>
          We may change our Privacy Policy from time to time and in our sole
          discretion. Housle encourages visitors to frequently check this page
          for any changes to its Privacy Policy. Your continued use of the Sites
          after any change in this Privacy Policy will constitute your
          acceptance of such change.
        </Text>
        <Title order={2}>CONTACT INFORMATION.</Title>
        <div>
          <Text>
            We welcome your comments or questions about this Policy. You may
            contact us at the following address:
          </Text>
          <Text>Housle, LLC</Text>
          <Anchor href="mailto:info@housle.house">info@housle.house</Anchor>
        </div>
      </Stack>
    </Container>
  );
};
