import React from "react";
import { Flex } from "@mantine/core";
import { useGame } from "hooks/useGame";
import CustomAds from "./CustomAds";
import GoogleAds from "./GoogleAds";

const AdsComponent: React.FC = () => {
  const { adsBanner } = useGame();

  return (
    <Flex align={"center"} justify="center">
      {adsBanner ? <CustomAds banner={adsBanner} /> : <GoogleAds />}
    </Flex>
  );
};

export default AdsComponent;
