import { Box, Button, Image, Stack, Text } from "@mantine/core";
import GalleryIcon from "images/gallery.svg";
import { History, Post, ResultBanner } from "types/common";
import AdsComponent from "components/AdsComponent";
import BannerAd from "./BannerAd";

type OverallStatsProps = {
  history: History;
  openGallery: () => void;
  post: Post;
  resultBanner: ResultBanner | null;
};

type StatBoxProps = {
  label: string;
  value: string | number;
};

const StatBox = ({ label, value }: StatBoxProps) => (
  <Box
    sx={(theme) => ({
      alignItems: "center",
      border: `1px solid ${theme.colors.poppy[0]}`,
      borderRadius: 4,
      display: "flex",
      justifyContent: "space-between",
      padding: 8,
    })}
  >
    <Text color="emerald.0" size={14} weight={600}>
      {label}
    </Text>

    <Text color="poppy.0" size={18} weight={500}>
      {value}
    </Text>
  </Box>
);

const OverallStats = ({
  history,
  openGallery,
  resultBanner,
  post,
}: OverallStatsProps) => {
  const { currentStreak, gamesPlayed, gamesWon, maxStreak } = history;

  return (
    <Box sx={{ background: "white", padding: "24px 32px" }}>
      <Box
        mb={16}
        sx={{
          display: "grid",
          gap: 8,
          gridTemplateColumns: "1fr",
        }}
      >
        <StatBox label="Games Played" value={gamesPlayed} />
        <StatBox
          label="Win Percentage"
          value={`${Math.ceil((gamesWon / gamesPlayed) * 100)}%`}
        />
        <StatBox label="Current Streak" value={currentStreak} />
        <StatBox label="Max Streak" value={maxStreak} />
      </Box>

      {resultBanner ? (
        <BannerAd
          address={post.address}
          backgroundImage={resultBanner.background}
          buttonCopy={resultBanner.buttonCopy}
          caption={resultBanner.caption}
          foregroundImage={resultBanner.foreground}
          logo={resultBanner.logo}
          price={post.asking_price}
          url={resultBanner.url}
          mb={16}
        />
      ) : (
        <Stack spacing={5} my={24}>
          <Button
            component="a"
            href={post.listing_link}
            target={"_blank"}
            rel="noreferrer noopener"
            sx={(theme) => ({
              fontSize: 14,
              letterSpacing: 2.5,
              lineHeight: "24px",
              padding: "8px 40px",
              textTransform: "uppercase",
              ":hover": {
                backgroundColor: theme.colors.emerald[0],
              },
            })}
          >
            View Listing
          </Button>
          <Text
            color="poppy.0"
            component="a"
            href={post.listing_similar}
            rel="noreferrer noopener"
            size={15}
            sx={{ textAlign: "center" }}
            target={"_blank"}
          >
            Similar Properties
          </Text>
        </Stack>
      )}

      <Stack spacing={5} sx={{ marginBottom: 16 }}>
        <Button
          variant="subtle"
          leftIcon={<Image src={GalleryIcon} alt="gallery" />}
          bg="transparent"
          onClick={openGallery}
          sx={(theme) => ({
            fontSize: 16,
            color: theme.colors.emerald[0],
            ":hover:enabled": {
              backgroundColor: "transparent",
            },
          })}
        >
          Today's Image Gallery
        </Button>
        <Text
          sx={(theme) => ({
            fontSize: 14,
            color: theme.colors.emerald[0],
            textAlign: "center",
          })}
        >
          Come Play Again Tomorrow!
        </Text>
      </Stack>

      <AdsComponent />
    </Box>
  );
};

export default OverallStats;
