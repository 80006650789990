import { useCallback, useEffect, useState } from "react";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "services/firestore";
import { Partner } from "types/common";

export const usePartner = (id?: string) => {
  const [partner, setPartner] = useState<Partner | null>(null);

  const getPartner = useCallback(async () => {
    if (id) {
      const ref = collection(db, "partners");
      const q = query(ref, where("id", "==", id), limit(1));
      const querySnapshot = await getDocs(q);
      const temp = [] as any[];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      setPartner(temp[0]);
    } else {
      setPartner(null);
    }
  }, [id]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  return { partner };
};
