import { Box, Image } from "@mantine/core";
import { AdsBanner } from "types/common";

type CustomAdsProps = {
  banner: AdsBanner;
};

const CustomAds = ({ banner }: CustomAdsProps) => {
  return (
    <Box sx={{ height: 70, marginBottom: 8, maxWidth: 320, width: "100%" }}>
      <a href={banner.link} target="_blank" rel="noreferrer">
        <Image src={banner.image} />
      </a>
    </Box>
  );
};

export default CustomAds;
