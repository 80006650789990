import { useState } from "react";
import { USER_HISTORY } from "../utils/static";
import { History } from "types/common";

// SAVE
// games played
// games won
// current streak
// max streak
// number of attempts
// last completed

const DEFAULT_HISTORY: History = {
  gamesPlayed: 0,
  gamesWon: 0,
  currentStreak: 0,
  maxStreak: 0,
  attempts: [],
  lastCompletedDay: undefined,
  lastSeenDay: undefined,
};

const getUserHistory = (): History => {
  const userHistory = localStorage.getItem(USER_HISTORY);
  return userHistory ? JSON.parse(userHistory) : DEFAULT_HISTORY;
};

export const useLocalStorage = () => {
  const [history, setHistory] = useState(getUserHistory());
  const [completedToday] = useState(() => {
    const currDay = new Date().getDate();
    return getUserHistory().lastCompletedDay === currDay;
  });
  const [playedToday, setPlayedToday] = useState(() => {
    const currDay = new Date().getDate();
    return getUserHistory().lastSeenDay === currDay;
  });

  const updateUserHistory = (attempts: number[], result: "win" | "lose") => {
    const userHistory = { ...history };
    userHistory.gamesPlayed += 1;
    userHistory.lastCompletedDay = new Date().getDate();
    userHistory.attempts = attempts;
    if (result === "win") {
      userHistory.gamesWon += 1;
      userHistory.currentStreak += 1;
      if (userHistory.currentStreak > userHistory.maxStreak) {
        userHistory.maxStreak = userHistory.currentStreak;
      }
    } else {
      userHistory.currentStreak = 0;
    }
    setHistory(userHistory);
    setPlayedToday(true);
    localStorage.setItem(USER_HISTORY, JSON.stringify(userHistory));
  };

  const setAttemptHistory = (attempts: number[]) => {
    const userHistory = { ...history };
    userHistory.attempts = attempts;
    setHistory(userHistory);
    localStorage.setItem(USER_HISTORY, JSON.stringify(userHistory));
  };

  const resetGameForNewDay = () => {
    const userHistory = { ...history };
    userHistory.attempts = [];
    userHistory.lastSeenDay = new Date().getDate();
    setHistory(userHistory);
    localStorage.setItem(USER_HISTORY, JSON.stringify(userHistory));
  };

  return {
    history,
    completedToday,
    playedToday,
    updateUserHistory,
    setAttemptHistory,
    resetGameForNewDay,
  };
};
